<template>
  <div class="error">
    <img src="@/assets/img/error/500.png" />
    <p>
      <span class="txt">{{time}}s</span>后返回首页
      <a
        @click="goTo"
        href="javascript:;"
      >立即返回</a>
    </p>
  </div>
</template>

<script>

export default {
  name: 'error500',
  data () {
    return {
      time: 3,
      timer: null,
      hasHistory: true,
    };
  },
  created () {
    this.reduceNum();
    document.title = '错误';
  },
  computed: {
    exhibitionId () {
      return (
        this.$router.history.current.params.id || this.$store.state.entranceId
      );
    }
  },
  beforeRouteEnter (to, from, next) {
    let hasHistory = true;
    if (!from.name && from.matched.length === 0) {
      hasHistory = false;
    }
    next(vm => vm.hasHistory = hasHistory);
  },
  methods: {
    reduceNum () {
      this.timer = setInterval(() => {
        --this.time;
        if (this.time === 0) {
          window.clearInterval(this.timer);
          this.goTo();
        }
      }, 1000);
    },
    goTo () {
      if (this.hasHistory) {
        this.$router.go(-1);
      } else {
        if (this.exhibitionId) {
          this.$router.push({ path: '/home/index/' + this.exhibitionId });
        }
      }
    }
  },
  destroyed () {
    this.timer = null;
  },
}

</script>

<style scoped lang='scss'>
.error {
  text-align: center;
  padding-top: 20%;
  p {
    margin-top: 40px;
  }
  img {
    max-width: 100%;
  }
  .txt,
  a {
    color: #1890ff;
  }
}
</style> 